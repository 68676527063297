import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css';
import Footer from '../common/footer/Footer';
import Navigation from '../common/navbar/Navigation';
import { Helmet } from 'react-helmet';
import { useGSAP } from '@gsap/react';
import { ScrollTop } from 'primereact/scrolltop';
import LocomotiveScroll from 'locomotive-scroll';
import Loader from '../GenericModel/Loader/Loader';

const scroll = new LocomotiveScroll();

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
    const heroRef = useRef(null);
    const featuresRef = useRef(null);
    const servicesRef = useRef(null);
    const containerRef = useRef(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const canvas = document.querySelector("#home>canvas");
        const context = canvas.getContext("2d");

        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        const images = [];
        const frameCount = 240; // Adjust as needed
        const imageSeq = { frame: 0 };
        let loadedImages = 0;

        // Dynamically generate image paths
        for (let i = 0; i < frameCount; i++) {
            const img = new Image();
            img.src = `./img/cpu/Image${i + 1}.webp`;
            img.onload = () => {
                loadedImages++;
                if (loadedImages === frameCount) {
                    setLoading(true); // All images loaded
                }
            };
            images.push(img);
        }


        // GSAP animation
        gsap.to(imageSeq, {
            frame: frameCount - 1,
            snap: "frame",
            ease: "none",
            scrollTrigger: {
                scrub: 1.5,
                pin: true,
                trigger: "#home",
                start: "top top",
                end: "bottom top",
            },
            onUpdate: render,
        });

        images[0].onload = render;

        function render() {
            scaleImage(images[imageSeq.frame], context);
        }

        function scaleImage(img, ctx) {
            const canvas = ctx.canvas;
            const hRatio = canvas.width / img.width;
            const vRatio = canvas.height / img.height;
            const ratio = Math.max(hRatio, vRatio);
            const centerShift_x = (canvas.width - img.width * ratio) / 2;
            const centerShift_y = (canvas.height - img.height * ratio) / 2;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, 0, 0, img.width, img.height, centerShift_x, centerShift_y, img.width * ratio, img.height * ratio);
        }

        const handleResize = () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            render();
        };

        gsap.to("#home canvas", {
            scale: 0.8,
            scrollTrigger: {
                scrub: .1,
                trigger: "#home",
                start: "bottom 100%",
                // markers:true,
            },
        })
        window.addEventListener("resize", handleResize);

        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    useEffect(() => {
        const scrollTimeout = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);

        return () => clearTimeout(scrollTimeout); // Cleanup timeout
    }, []);


    useGSAP(() => {
        // Hero section animation on load
        gsap.to("#welcome-container h1", {
            y: "-200%",
            scale: 10,
            fontSize: 1000,
            duration: 0.5,
            delay: 0.2,
            opacity: 0.8,
            scrollTrigger: {
                trigger: "#welcome-container",
                start: "top 0%",
                end: "bottom",
                scrub: 1,
                pin: true,
                pinSpacing: true,
            },
        });

        // Animation for hero section content fading in
        gsap.fromTo(
            heroRef.current.querySelectorAll("h1, p, button"),
            { opacity: 0, y: 50, scale: 0.8 },
            {
                opacity: 1,
                y: 0,
                scale: 1,
                duration: 1,
                delay: 0.5,
                stagger: 0.3,
                scrollTrigger: {
                    scrub: 1,
                    trigger: heroRef.current,
                    start: "top 50%", // Start fading in as the welcome animation ends
                    end: "top 30%"
                },
            }
        );



        // Mouse move animation
        const handleMouseMove = (e) => {
            // console.log(e.x)
            gsap.to("#cursor", {
                x: e.x - 7,
                y: e.y - 7,
                duration: 0.2,
                // delay: 0.2,
                ease: "elastic.out(1,0.3)",
            });
        };

        window.addEventListener('mousemove', handleMouseMove);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <>
            <Helmet>
                <meta property="og:image" content={`https://techveda.org.in/og-image.png`} />
                <meta property="og:image:alt" content="Techveda software solutions" />
                <meta property="og:image:width" content="1200" />  {/* Replace with your actual image width */}
                <meta property="og:image:height" content="630" />
            </Helmet>
            <Navigation />
            {loading ? <Loader show={loading}/> : ""}
            <div className="container">
                <div className="main-welcom-container">
                    <div className="welcom-bg"></div>
                    <div id="welcome-container">
                        <h1>Welcome</h1>
                    </div>
                </div>
                {/* <div id="cursor"></div>7y */}
                {/* Hero Section */}
                <section ref={heroRef} className="hero-section section">
                    <h1>Welcome to Techveda</h1>
                    <p>Empowering businesses with innovative software solutions and cutting-edge technology.</p>
                    <button className="btn btn-primary">Discover More</button>
                </section>

                {/* Features Section */}
                <section className="features-section" ref={containerRef} data-scroll-container>
                    <div id="home">
                        <canvas></canvas>
                    </div>

                    <div className="txt-home">
                        <div className="text-center features-text">
                            <h3>Expert Development</h3>
                            <p>Our expert team specializes in developing reliable, high-quality software solutions tailored to your specific needs. With a focus on innovation and precision, we deliver customized products that drive success, ensuring seamless performance and exceptional results for your business. Let us transform your vision into reality!</p>
                        </div>
                        <div className="text-center features-text">
                            <h3>Scalable Solutions</h3>
                            <p>We create scalable, future-ready platforms designed to grow alongside your business. Our solutions ensure seamless adaptability, empowering your enterprise to thrive and achieve long-term success in an ever-evolving market.</p>
                        </div>
                        <div className="text-center features-text">
                            <h3>Client-Centric Approach</h3>
                            <p>Our client-centric approach places your needs at the forefront, ensuring exceptional service and tailored solutions. By focusing on understanding your goals, we deliver unmatched results that exceed expectations, fostering long-term partnerships built on trust, reliability, and success. Your satisfaction is our ultimate priority.</p>
                        </div>
                    </div>
                </section>

                {/* Services Section */}
                <section className="services-section section h-100">
                    <h2>Our Services</h2>
                    <p className="ps-3 pe-3">At Techveda, we deliver a comprehensive range of IT services to meet your business's technology needs. Our expertise spans from modern web and mobile app development to advanced cloud solutions and API integrations. Whether you're seeking custom software, secure IT infrastructure, or data-driven AI solutions, we have you covered.</p>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card p-3">
                                <h4>Web Development</h4>
                                <p>We design and develop responsive, user-centric websites using cutting-edge technologies like React, Angular, and more. Our solutions ensure seamless functionality and an exceptional user experience on all devices.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card p-3">
                                <h4>Mobile App Development</h4>
                                <p>Our mobile app development services focus on creating high-performance Android and iOS apps, integrating user-friendly interfaces and innovative features for maximum engagement.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card p-3">
                                <h4>API Development & Integration</h4>
                                <p>We specialize in creating reliable APIs and seamless integration services, enabling your applications to connect and perform efficiently with external systems.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card p-3">
                                <h4>Cloud Solutions</h4>
                                <p>Our cloud offerings include secure migration, storage, and computing solutions. Enjoy flexibility, scalability, and peace of mind knowing your data is secure.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card p-3">
                                <h4>Custom Software Development</h4>
                                <p>We build tailored software applications, combining the latest tech stacks with your specific business requirements to deliver scalable and efficient solutions.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card p-3">
                                <h4>IT Consulting</h4>
                                <p>Leverage our expertise to align your IT strategy with your business goals. From planning to execution, we provide the right solutions to optimize operations.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card p-3">
                                <h4>Data Analytics & Artificial Intelligence</h4>
                                <p>Harness your data's full potential with our advanced analytics, machine learning models, and AI-driven insights to make data-backed decisions.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card p-3">
                                <h4>Cybersecurity Services</h4>
                                <p>Protect your business from digital threats with our comprehensive security services, including penetration testing, network security, and data encryption.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card p-3">
                                <h4>UI/UX Design</h4>
                                <p>We craft intuitive designs that blend creativity and functionality, ensuring an engaging and effortless user experience across all platforms.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card p-3">
                                <h4>E-commerce Development</h4>
                                <p>Boost your online presence with fully customized e-commerce platforms designed for optimal performance and seamless transactions.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card p-3">
                                <h4>DevOps Solutions</h4>
                                <p>Streamline your software development lifecycle with our DevOps services, ensuring faster delivery, robust infrastructure, and efficient operations.</p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card p-3">
                                <h4>IT Infrastructure Setup & Maintenance</h4>
                                <p>From network configuration to server management, we provide reliable IT infrastructure solutions to support your growing business needs.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
            <ScrollTop className='scroll-top' />
        </>
    );
};

export default Home;
