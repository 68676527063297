import { Children } from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import "./Loader.css";

const Loader = ({ show }) => {
  useEffect(() => {
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, []);

  if (!show) return null;

  return ReactDOM.createPortal(
    <>
      <div className="blure-container"></div>
      <div className="spinner-container-main">
        <div className="spinner-container">
          <div class="spinner">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </>,
    document.querySelector(".loader")
  );
};

export default Loader;
