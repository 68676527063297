import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './components/home/Home.jsx';
import About from './components/about/About.jsx';
import ServicesPage from './components/service/ServicesPage.jsx';
import ContactPage from './components/contact/ContactPage.jsx';
import ServiceDetailPage from './components/service/ServiceDetailPage.jsx';
import Loader from './components/GenericModel/Loader/Loader.jsx'
import { useEffect, useState } from 'react';
import LocomotiveScroll from 'locomotive-scroll';

const scroll = new LocomotiveScroll();

function App() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);
    return (
        <>
            {loading ? <Loader show={true} /> : (
                <>
                    <Router>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path='/about' element={<About />} />
                            <Route path='/services' element={<ServicesPage />} />
                            <Route path='/contact' element={<ContactPage />} />
                            <Route path="/services/:serviceId" element={<ServiceDetailPage />} />
                        </Routes>
                    </Router>
                </>
            )}
        </>
    );
}

export default App;